<template>
  <el-card>

    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="120px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="用户图像">
            <img
              v-show="avatar"
              width="40"
              :src="avatar"
              style="cursor: pointer"
              @click="logoFileClick"
            >
            <el-button
              v-show="!avatar"
              size="small"
              @click="logoFileClick"
            >
              上传图片
            </el-button>
            <input
              id="logoFile"
              accept="image/*"
              type="file"
              style="display: none"
              @change="fileSelect"
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="邮箱"
            prop="email"
          >
            <el-input
              v-model="form.email"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="密码"
            prop="password"
            :rules="form.id ? [] : [ { required: true, message: '密码不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.password"
              :type="pwdType ? 'password' : 'text'"
            >
              <i
                slot="suffix"
                class="el-icon-view cursor-pointer"
                @click="pwdType=!pwdType"
              />
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="姓名"
            prop="actualName"
            :rules="[ { required: true, message: '姓名不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.actualName"
              v-input-filter
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="手机号码"
            prop="mobile"
          >
            <el-input
              v-model="form.mobile"
              v-input-filter
              type="number"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="办公电话"
          >
            <el-input
              v-model="form.officePhone"
              v-input-filter
              type="number"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="职位"
          >
            <el-input
              v-model="form.jobTitle"
              v-input-filter
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="状态"
            prop="status"
            :rules="[ { required: true, message: '状态不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.status"
              placeholder="请选择状态"
            >
              <el-option
                v-for="(item,index) in [{label:'启用',value:1},{label:'停用',value:2}]"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="角色"
            prop="roles"
            :rules="[ { required: true, message: '角色不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.roles"
              multiple
              placeholder="请选择角色"
            >
              <el-option
                v-for="(item,index) in roles"
                :key="index"
                :label="item.roleName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="部门"
            prop="deptList"
            :rules="[ { required: true, message: '部门不能为空', trigger: 'change' }]"
          >
            <SelectTreeMultiple
              ref="selectTreeMultiple"
              :value.sync="form.deptList"
              :props="treeSelectProps"
              :options="deptOptions"
              :datas="depts"
              :multiple="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item
          label="备注"
          prop="remark"
        >
          <el-input
            v-model="form.remark"
            v-input-filter
          />
        </el-form-item>
      </el-row>
    </el-form>
    <div
      class="dialog-footer text-center"
    >
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-card>

</template>

<script>
import SelectTreeMultiple from '@core/components/select-tree-multiple/SelectTreeMultiple.vue'
import { QueryDeptOptions } from '@/api/system/dept/dept'
import { saveOrUpdateUser, QueryUserInfo } from '@/api/system/user/user'
import { QueryRoleOptions } from '@/api/system/role/role'
import {
  success, error, verifyEmali, verifyPhone, treeDataTranslate,
} from '@core/utils/utils'
import { UploadFile } from '@/api/system/system'

export default {
  name: 'UserEdit',
  components: { SelectTreeMultiple },
  data() {
    return {
      pwdType: true,
      avatar: '',
      depts: [],
      deptOptions: [],
      roles: [],
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'label', // 显示名称
        children: 'children', // 子级字段名
      },
      loading: false,
      form: {
        deptList: [],
        roles: [],
        account: '',
        password: '',
        jobTitle: '',
        officePhone: '',
        mobile: '',
        actualName: '',
        remark: '',
        email: '',
        avatar: '',
        status: 1,
        id: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: '邮箱不能为空',
            trigger: 'change',
          },
          {
            validator: verifyEmali,
            trigger: 'change',
          },
        ],
        mobile: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'change',
          },
          {
            validator: verifyPhone,
            trigger: 'change',
          },
        ],
      },
    }
  },
  created() {
    this.queryDeptOptions()
    this.queryRoleOptions()
    if (this.$route.query.id) {
      this.form.id = Number(this.$route.query.id)
      this.queryUserInfo()
    }
  },
  mounted() {
    this.navActiveInit(1, '用户管理', '企业管理')
  },
  destroyed() {
    this.navActiveInit(2, '用户管理', '企业管理', '/settings/user')
  },
  methods: {
    logoFileClick() {
      document.getElementById('logoFile').click()
    },
    fileSelect() {
      const uploadDom = document.getElementById('logoFile')
      const formData = new FormData()
      formData.append('file', uploadDom.files[0])
      UploadFile(formData).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.avatar = resData.data.assessPath
          this.form.avatar = resData.data.filePath
        } else {
          error(resData.msg)
        }
      })
    },
    queryUserInfo() {
      QueryUserInfo(this.form.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const userData = resData.data
          this.form.account = userData.account
          this.form.mobile = userData.mobile
          this.form.password = userData.password
          this.form.jobTitle = userData.jobTitle
          this.form.officePhone = userData.officePhone
          this.form.actualName = userData.actualName
          this.form.remark = userData.remark
          this.form.status = userData.status
          this.form.email = userData.email
          userData.deptList.forEach(d => {
            this.form.deptList.push(d.id)
          })
          userData.roleResource.roles.forEach(d => {
            this.form.roles.push(d.id)
          })
          this.avatar = resData.data.avatar
          this.form.avatar = resData.data.avatar
        } else {
          error(resData.msg)
        }
      })
    },
    queryDeptOptions() {
      QueryDeptOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.depts = []
          resData.data.forEach(d => {
            this.depts.push(d)
          })
          // this.depts = resData.data
          this.deptOptions = treeDataTranslate(resData.data, 'id', 'parentId')
        }
      })
    },
    queryRoleOptions() {
      QueryRoleOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.roles = resData.data
        }
      })
    },
    back() {
      this.$router.push({
        name: 'user',
      })
    },
    // eslint-disable-next-line vue/no-dupe-keys
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          const submitData = {
            deptList: [],
            roles: [],
            account: this.form.email,
            avatar: this.form.avatar,
            password: this.form.password,
            remark: this.form.remark,
            mobile: this.form.mobile,
            jobTitle: this.form.jobTitle,
            officePhone: this.form.officePhone,
            actualName: this.form.actualName,
            email: this.form.email,
            status: this.form.status,
          }
          if (this.form.id) {
            submitData.id = this.form.id
          }
          this.form.deptList.forEach(dept => {
            const dsd = {
              id: dept,
            }
            submitData.deptList.push(dsd)
          })
          this.roles.forEach(d => {
            this.form.roles.forEach(role => {
              if (d.id === role) {
                submitData.roles.push(d)
              }
            })
          })
          saveOrUpdateUser(submitData).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
  },

}
</script>
<style scoped>

</style>
